function getLocale(language) {
    switch (language) {
        case 'german':
        case 'simpleGerman':
        return 'de';
        
        case 'english':
        return 'en';
        
        default:
        return 'en';
    }
}
    
export function formatDate(language, date) {
    try {
        const timestamp = Date.parse(date);
        if (Number.isNaN(timestamp)) {
        console.warn('Received invalid date for formatting:', date);
        return date;
    }
    
    const parsed = new Date(timestamp);
    const locale = getLocale(language);
    const formatted = parsed.toLocaleDateString(locale, {
        day: 'numeric',
        month: 'short',
    });
    
    return formatted;
        } catch (e) {
            console.error('An error occured while formatting "' + date + '":', e);
            return date;
        }
}

export function formatDateNoMonth(language, date, enddate) {
    try {
        const timestamp = Date.parse(date);
        if (Number.isNaN(timestamp)) {
        console.warn('Received invalid date for formatting:', date);
        return date;
    }
    
    const parsed = new Date(timestamp);
    const locale = getLocale(language);
    const formatted = parsed.toLocaleDateString(locale, {
        day: 'numeric',
    });
    
    return formatted;
        } catch (e) {
            console.error('An error occured while formatting "' + date + '":', e);
            return date;
        }
}

    /*
    dann so importieren:
    import { formatDate } from '../../utils/formatDate.js'
    
    und so verwenden:
    <div> { formatDate(l, v.tag.Datum) } </div>
    */

export function formatDateLong(language, date) {
    try {
        const timestamp = Date.parse(date);
        if (Number.isNaN(timestamp)) {
        console.warn('Received invalid date for formatting:', date);
        return date;
    }
    
    const parsed = new Date(timestamp);
    const locale = getLocale(language);
    const formatted = parsed.toLocaleDateString(locale, {
        weekday: 'long',
        day: 'numeric',
        month: 'short',
        year: 'numeric',
    });
    
    return formatted;
        } catch (e) {
            console.error('An error occured while formatting "' + date + '":', e);
            return date;
        }
}

export function formatDateBlog(language, date) {
    try {
        const timestamp = Date.parse(date);
        if (Number.isNaN(timestamp)) {
        console.warn('Received invalid date for formatting:', date);
        return date;
    }
    
    const parsed = new Date(timestamp);
    const locale = getLocale(language);
    const formatted = parsed.toLocaleDateString(locale, {
        day: 'numeric',
        month: 'long',
        year: 'numeric',
    });
    
    return formatted;
        } catch (e) {
            console.error('An error occured while formatting "' + date + '":', e);
            return date;
        }
}

/**
 * Returns the name of the weekday of the given date.
 * @param {'german' | 'simpleGerman' | 'english'} language
 * @param {Date} date
 */
export function getDayName(language, date) {
  if (!date) {
    console.warn('getDayName() was called without passing a date');
    return { short: '', long: '' };
  }
  const parsedDate = new Date(Date.parse(date));
  const locale = getLocale(language);

  const formattedLong = parsedDate.toLocaleDateString(locale, {
    weekday: 'long',
    timeZone: 'UTC',
  });

  const formattedShort = parsedDate.toLocaleDateString(locale, {
    weekday: 'short',
    timeZone: 'UTC',
  });

  return {
    long: formattedLong,
    short: formattedShort,
  };
}
