import React, { useContext } from "react"
import { Link, graphql } from "gatsby"
import Template from "./template"
import "../styles/blogposts.css"
import { SettingsContext } from "../contexts/settings"
import { translate } from "../../utils/language" 
import {BlogpostDownload} from "./BlogpostDownload"
import {BlogpostBildMitText} from "./BlogpostBildMitText"
import {BlogpostText} from "./BlogpostText"
import {BlogpostGallerie} from "./BlogpostGallerie"
import {BlogpostVideo} from "./BlogpostVideo"
import {BlogpostExternerInhalt} from "./BlogpostExternerInhalt"
import {BlogpostExternerInhaltMitText} from "./BlogpostExternerInhaltMitText"
import {formatDateBlog} from "../../utils/formatdate"


const BlogpostsPage = ({ data }) => {
    const [settings,] = useContext(SettingsContext)
    const l = settings.language
    const b = data.strapi.blogPost
    return (
        <Template currentPage="blog">
            <section className="end-of-site">
                <h1>
                    <Link className="back-arrow" to="/blog">BLOG &#60;&#60; </Link>
                    {translate(l, b.Titel, b.Titel_en)}
                </h1>
                <p>{formatDateBlog(l, b.Datum)}</p>
                {
                    b.Inhalt.map(blogComponent => {
                        switch (blogComponent.__typename) {
                            case "Strapi_ComponentDefaultMehrsprachigerDownload":
                                return <BlogpostDownload data={blogComponent} language={l} />;
                            case "Strapi_ComponentDefaultBildMitMehrsprachigemText":
                                return <BlogpostBildMitText data={blogComponent} language={l} />;
                            case "Strapi_ComponentDefaultMehrsprachigerText":
                                return <BlogpostText data={blogComponent} language={l} />;
                            case "Strapi_ComponentDefaultGallerie":
                                return <BlogpostGallerie data={blogComponent} language={l} />;
                            case "Strapi_ComponentDefaultVideo":
                                return <BlogpostVideo data={blogComponent} />;
                            case "Strapi_ComponentDefaultExternerInhalt":
                                return <BlogpostExternerInhalt data={blogComponent} />;
                            case "Strapi_ComponentDefaultExternerInhaltMitText":
                                return <BlogpostExternerInhaltMitText data={blogComponent} language={l} />;
                            case "Strapi_ComponentDefaultTrennstrich":
                                return <div className="mint-line-blogpost" data={blogComponent}/>;
                        }
                    }


                    )
                }


            </section>
        </Template>
    )
}

export default BlogpostsPage

export const query = graphql`
  query($id: ID!) {
        strapi {
          blogPost(id: $id) {
            Datum
            Inhalt {
                __typename  
              ... on Strapi_ComponentDefaultMehrsprachigerDownload {
                Titel_englisch
                Titel_deutsch
                Link_deutsch
                Link_englisch
                Download_deutsch {
                  url
                }
                Download_englisch {
                  url
                }
              }
              ... on Strapi_ComponentDefaultBildMitMehrsprachigemText {
                Deutsch
                Englisch
                Titel_deutsch
                Titel_englisch
                bild {
                    Deutsch
                    Englisch
                    Untertitel_de
                    Untertitel_en
                    Bild {
                    url
                    }
                }
              }
              ... on Strapi_ComponentDefaultVideo {
                VideoDatei {
                  url
                }
              }
              ... on Strapi_ComponentDefaultMehrsprachigerText {
                Deutsch
                Englisch
              }
              ... on Strapi_ComponentDefaultGallerie {
                Bilder {
                  Bild {
                    url
                  }
                  Deutsch
                  Englisch
                  Untertitel_de
                  Untertitel_en
                }
              }
              ... on Strapi_ComponentDefaultExternerInhalt {
                URL
              }
              ... on Strapi_ComponentDefaultTrennstrich {
                id
              }
              ... on Strapi_ComponentDefaultExternerInhaltMitText {
                Deutsch
                Englisch
                URL
              }
            }
            readable_id
            Titel
            Titel_en
          }
        }
      }
    `